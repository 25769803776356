export default {	
	//字符串中间*代替
	strFilter:function(value,start=3,end=4){
		if(value){
			const valueArray=value.split("");
			for(let i=start;i<valueArray.length-end;i++){
				valueArray[i]="*"
			}
			return valueArray.join("")
		}			
	},
	//字符串中间*代替
	emailFilter:function(value){
		if(value){
			let str = value.split("@");
			return str[0].substr(0, 1) + "***" +str[0].substr(str[0].length - 1, 1) +'@'+ str[1];
		}			
	},
	//金钱格式保留两位
	moneyFilter :function(value){
		return parseFloat(value).toFixed(2);			
	},
	//金钱格式,隔开
	moneyFilterDot:function(value){
		return  Number(value).toFixed(2).replace(/^(\d*).(\d*)/, (...args) => args[1].replace(/\B(?=(?:\d{3})+$)/g, ',') + '.' + args[2]);			
	},
	//时间戳转成年月日
	formatDateTime:function(value) {
		return uni.gJS.toDate(value,'str');
	},
	//获取路径url的参数
	urFilter:function(value){
		let strUrl = decodeURIComponent(value);
		let paramUrl=strUrl.split('?')[1];
		let paramArr = paramUrl.split('&');
		var queryArr={};
		for(var i=0;i<paramArr.length;i++){
			let item = paramArr[i].split('=');
			queryArr[item[0]] = item[1];		
		}
		return queryArr;
	},
	//距离
	distanceFilter:function(value){
		if(value<1000)
		{
			return parseInt(value)+'m';
		}else{
			return parseFloat((value/1000)).toFixed(2)+'km';
		}
	},
	//保留姓氏
	nameFilter:function(value){
		return value.substring(0,1)+'师傅';
	}
}
