<template>
  <header class="header">
    <div class="header-main">
      <a href="/">
        <el-image style="width: 60px; height: 60px" src="/images/logo.png" fit="contain"></el-image>
      </a>
      <template v-if="!$route.meta.alive">
        <el-button v-if="!userInfo" size="large" type="primary" @click="tourl('/login')">登录</el-button>
        <div v-else class="flex align-center">
          <el-image v-if="userInfo.isVip==1" style="width: 50px;" src="/images/vipd.png" fit="contain" @click="showVip(true)"></el-image>
          <el-image v-else style="width: 50px;" src="/images/vipdd.png" fit="contain" @click="showVip(true)"></el-image>
          <el-button class="ml20" size="large" type="primary" icon="Remove" @click="logoOut()">退出登录</el-button>
        </div>
      </template>
    </div>
  </header>
  <div style="height: 85px"></div>
  <router-view :key="$route.fullPath" @showVip="showVip"/>
  <footer class="footer" v-if="!$route.meta.alive">
    <div class="flex justify-center">
      <div class="text-center">
        <div class="f18">在线客服</div>
        <el-image class="mt10" style="width: 120px; height: 120px" src="https://static.zaixiankaoshi.com/img/dab0a62.jpg" fit="contain"></el-image>
        <div class="mt10">扫码咨询相关问题</div>
      </div>
      <div class="text-center" style="margin-left: 40px">
        <div class="f18">微信小程序</div>
        <el-image class="mt10" style="width: 120px; height: 120px" src="https://static.zaixiankaoshi.com/img/dab0a62.jpg" fit="contain"></el-image>
        <div class="mt10">扫码进入小程序</div>
      </div>
      <div style="margin-left: 140px">
        <div class="f18">联系我们</div>
        <div class="mt50">工作时间：周一至周五 10:00 - 19:00</div>
        <div class="mt10">辽宁省沈阳市铁西区</div>
      </div>
    </div>
    <div class="text-center" style="margin-top: 30px">
      <p>Copyright © 2022 All Rights Reserved <a href="https://beian.miit.gov.cn/">沪ICP备19568987号-2</a></p>
      <a target="_blank" href="/">沪公网安备 31xd112552014II27号</a>
    </div>
  </footer>
  <el-dialog
      @close="closeDialog()"
      title=""
      v-model="vipVisible"
      :close-on-press-escape="false"
      top="3vh"
      custom-class="vipBox"
      width="650px">
      <div class="vipBoxT">
        <p>我要购买会员</p>
        <p v-if="userInfo.isVip==1" class="f16 mt10">到期时间: {{toDate(userInfo.vipEndTime,'str')}}</p>
      </div>
      <div class="vipBoxB">
        <div class="f18 fb mb15">VIP特权一览</div>
        <div class="box flex align-center space-between text-center">
          <div>
            <el-image style="width: 60px; height: 60px" src="/images/icon1.png" fit="contain"></el-image>
            <p class="c4F2901 f16">全站畅刷</p>
          </div>
          <div>
            <el-image style="width: 60px; height: 60px" src="/images/icon2.png" fit="contain"></el-image>
            <p class="c4F2901 f16">专享题库</p>
          </div>
          <div>
            <el-image style="width: 60px; height: 60px" src="/images/icon3.png" fit="contain"></el-image>
            <p class="c4F2901 f16">历年真题</p>
          </div>
          <div>
            <el-image style="width: 60px; height: 60px" src="/images/icon4.png" fit="contain"></el-image>
            <p class="c4F2901 f16">模拟考试</p>
          </div>
          <div>
            <el-image style="width: 60px; height: 60px" src="/images/icon5.png" fit="contain"></el-image>
            <p class="c4F2901 f16">无线错题本</p>
          </div>
          <div>
            <el-image style="width: 60px; height: 60px" src="/images/icon6.png" fit="contain"></el-image>
            <p class="c4F2901 f16">无限收藏夹</p>
          </div>
        </div>
        <div class="f18 fb mb15">VIP套餐选择</div>
        <div v-if="config" class="box flex align-items space-between">
          <div class="c4F2901">
            <p class="f18">体验会员{{config.vipTime}}天</p>
            <p class="mt10">消耗{{config.vipScore}}积分</p>
          </div>
          <el-button size="large" type="warning" @click="getVipByScore()" round>领 取</el-button>
        </div>
        <div v-if="vipList" class="flex align-center space-between text-center">
          <div @click="buyVip(index)"  v-for="(item,index) in vipList" :key="index" :class="['c4F2901 box',index==0?'':'ml20',index==buyIndex?'bgActive':'']">
            <p class="f18 fb">{{item.title}}</p>
            <p class="mt10"><span style="font-size: 30px; color: #f7533b;">{{item.price}}</span>元</p>
            <p class="f18 mb15" style="text-decoration: line-through; color: #B4967A">{{item.rePrice}}元</p>
            <el-button size="small" type="warning" round>开 通</el-button>
          </div>
        </div>
        <div class="box2 flex align-items space-between">
          <div class="flex align-items ">
            <div id="qrcode" ref="qrcode"></div>
            <div class="ml20" v-if="buyIndex>-1" >
              <p class="f18 c4F2901">{{vipList[buyIndex].title}} ({{vipList[buyIndex].days}}天 )</p>
              <p class="mt10">实付: <span style="font-size: 20px; color: #F7533B;">{{vipList[buyIndex].price}}</span>元</p>
              <p class="mt10 c999999">微信扫码支付</p>
            </div>
          </div>
          <el-button size="large" type="success" round  @click="vipVisible=false">确认支付完成</el-button>
        </div>
      </div>
  </el-dialog>
<!--  {{$store.state.isShowVip}}-->
</template>
<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import QRCode from 'qrcodejs2'
export default {
  name: 'appMain',
  data(){
    return{
      vipVisible: false, // 购买会员
      config:null,
      vipList:null,
      buyIndex:-1,
      userInfo:null
    }
  },
  created(){
    if(this.getLocalStorage('userInfo'))
    {
      this.getConfig()
      this.getVipList()
      this.getUserInfo()
    }
  },
  methods:{
    // 时间戳转 YY-mm-dd HH:ii:ss
    toDate: function(timeStamp, returnType) {
      timeStamp = parseInt(timeStamp);
      var date = new Date();
      if (timeStamp < 90000000000) {
        date.setTime(timeStamp * 1000);
      } else {
        date.setTime(timeStamp);
      }
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? ('0' + m) : m;
      var d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      var h = date.getHours();
      h = h < 10 ? ('0' + h) : h;
      var minute = date.getMinutes();
      var second = date.getSeconds();
      minute = minute < 10 ? ('0' + minute) : minute;
      second = second < 10 ? ('0' + second) : second;
      if (returnType == 'str') {
        return y + '-' + m + '-' + d;
        // return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
      }
      return [y, m, d, h, minute, second];
    },
    //关闭购买会员弹框
    closeDialog(){
      this.getUserInfo()
    },
    //获取用户详情
    async getUserInfo() {
      const res = await this.$gRequest.post('/swoft/api/user/getUserInfo', {id: this.getLocalStorage('userInfo','id')},true,true)
      if(res.code==0) {
        this.setLocalStorage('userInfo',res.data)
        this.userInfo = res.data;
      }
    },
    //购买会员
    async buyVip(index) {
      if(index != this.buyIndex) {
        this.buyIndex = index
        const query = {
          uid: this.getLocalStorage('userInfo','id'),
          openid:'',
          userVipId: this.vipList[index].id,
          price: this.vipList[index].price,
          days: this.vipList[index].days,
          type:'native'
        }
        const res = await this.$gRequest.post('/swoft/api/vip/buyVip', query,true,true)
        if(res.code==0) {
          document.getElementById("qrcode").innerHTML = "";
          const qrcode = new QRCode('qrcode', {
            width: 100, // 二维码宽度，单位像素
            height: 100, // 二维码高度，单位像素
            text: res.data.code_url, // 生成二维码的链接
          })
          console.log('二维码信息：', qrcode)
        } else {
          ElMessage({
            type: 'warning',
            message: res.msg,
          })
        }
      }
    },
    //购买体验会员
    async getVipByScore() {
      const query = {
        uid: this.getLocalStorage('userInfo','id'),
        price: this.config.vipScore,
        days: this.config.vipTime
      }
      const res = await this.$gRequest.post('/swoft/api/vip/getVipByScore', query,true,true)
      if(res.code==0) {
        ElMessage({
          type: 'success',
          message: res.msg,
        })
        this.vipVisible = false
      } else {
        ElMessage({
          type: 'warning',
          message: res.msg,
        })
      }
    },
    //获取配置项
    async getConfig() {
      const res = await this.$gRequest.post('/swoft/api/config/getConfig', {},true,false)
      if(res.code==0) {
        this.config = res.data
      }
    },
    //获取会员列表
    async getVipList() {
      const res = await this.$gRequest.post('/swoft/api/vip/getVipList', {},true,true)
      if(res.code==0) {
        this.vipList = res.data
      }
    },
    showVip(e){
      this.vipVisible = e
      if(this.vipVisible) {
        this.$nextTick(() => {
          this.buyVip(0)
        })
      }
    },
    logoOut(){
        ElMessageBox.confirm(
            '您确定要退出当前登录吗?',
            '退出提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }
        ).then(() => {
          localStorage.removeItem('userInfo');
          localStorage.removeItem('uToken');
          ElMessage({
            type: 'success',
            message: '退出账号成功',
          })
          setTimeout(()=>{
            location.href="/login"
          },1000)
        })
    }
  }
}
</script>
<style>
.c4F2901{ color: #4F2901;}
.vipBox header{  height: 0; padding: 0;}
.vipBox header .el-dialog__close{ color:#FFFFFF; font-size: 20px;}
.vipBox .el-dialog__body{ padding: 0}
.vipBoxT{ color: #f9d681; text-align: center; font-size: 30px; padding:20px; background:url("./assets/vip.png") no-repeat center center;background-size:cover;overflow:hidden;}
.vipBoxB{ padding: 20px;}
.box{ width: 100%; margin-bottom: 30px; padding: 20px; background: #FBF8EF; border:#F5EAE5 1px solid; border-radius: 10px;}
.box2{ width: 100%; margin-bottom: 30px; padding: 20px; background: #fefefe; border:#F5EAE5 1px solid; border-radius: 10px;}
.bgActive{ background: #ffe2d4;}
body{ list-style:none; background-color: #f5f5f5; color: #333;}
div{box-sizing: border-box;}
*{list-style: none;margin:0;padding:0;}
.header{ width: 100%; position: fixed; top: 0; left: 0; padding: 10px 0; background-color: #ffffff; z-index: 9;}
.header-main{ max-width: 1200px; margin: 0 auto; display: flex; align-items: center; justify-content: space-between}
.footer{ background-color: #2a2c35; padding: 30px 0 24px; color: #ccc; font-size: 13px;}
.footer a { color: #ccc;}
.layout-container { margin: 0 auto; max-width: 1200px; min-height:calc(100vh - 500px);; }
.flex{ display: flex;}
.space-between{ justify-content: space-between}
.align-items{align-items:center; }
.flex-wrap{flex-wrap: wrap;}
.justify-center{ justify-content: center}
.justify{ justify-content: start}
.fb{ font-weight: 700;}
.bgFF{ background-color: #ffffff;}
.wp100{ width: 100%;}
.no-scale{flex-shrink:0;}
.mt10{ margin-top: 10px;}
.mt15{ margin-top: 15px;}
.ml10{ margin-left: 10px;}
.ml20{ margin-left: 20px;}
.mt50{ margin-top: 50px;}
.mb15{ margin-bottom: 15px;}
.mb30{ margin-bottom: 30px;}
.pb20{ padding-bottom: 20px;}
.pb10{ padding-bottom: 10px;}
.cf60d10{color: #f60d10;}
.c999999{color:#999999;}
.f18{ font-size: 18px}
.f16{ font-size: 16px}
.f14{ font-size: 14px}
.line1{ line-height: 1.5}
.p25{ padding: 25px;}
.csuccess{ color: #13ce66}
.cdanger{ color: #ff4949}
.c409eff{ color: #409eff}
.text-center{ text-align: center;}
.ddbottom{border-bottom: 1px solid #eeeeee;}
a{text-decoration: none; color: #333;}
.pbd{padding:16px 24px}
</style>
