// 加载配置
import requestConfig from './config.js'
import md5 from "js-md5"
import axios from "axios";
import {ElMessage} from "element-plus";

// 核心工具定义
export default{

    // token 数据记录
    token    : '',
    // 从服务器获取 token 函数
    getDoToken : function (resolve, reject){
        return new Promise(async(resolve, reject) =>{
            var res = await  axios.post(`/swoft/api/token/getReqToken`,{ 'appKey' : requestConfig.appKey })
            if(res.status == 200 && res.data.code == 0)
            {
                // 记录时间
                var dateObj = new Date();
                var cTime   = dateObj.getTime();
                cTime      += '';
                localStorage.setItem(requestConfig.localTokenKeyName, res.data.data)
                localStorage.setItem('GraceRequestTokenTime', cTime);
                resolve(res.data.data);
            }else{
                reject();
            }
        })
    },

    //
    getTokenFromApi : function (count){
        return new Promise((resolve, reject) => {
            this.debug('第 '+count+' 次 token 请求');
            let p = this.getDoToken();
            p.then((res)=>{
                this.token = res;
                resolve(res);
            }).catch((err)=>{
                reject('token error');
            });
        });
    },

    // 获取 token
    getToken : function(count){
        return new Promise((resolve, reject) => {
            // 首先尝试从本地获取
            var token = localStorage.getItem(requestConfig.localTokenKeyName);

            // 本地不存在 token 数据
            // 执行 token 请求函数获取 token
            if(!token || token == ''){
                let p = this.getTokenFromApi(1);
                p.then((res)=>{
                    this.token = res;
                    resolve(res);
                }).catch((err)=>{
                    let p2 = this.getTokenFromApi(2);
                    p2.then((res)=>{
                        this.token = res;
                        resolve(res);
                    }).catch((err)=>{
                        reject(err);
                    });
                });
            }
            // 存在 token 数据
            else{
                // 检查 token 有效期
                var expiredTime = requestConfig.expiredTime;
                expiredTime    *= 1000;
                var tokenTime   = Number(localStorage.getItem('GraceRequestTokenTime'));
                tokenTime      += expiredTime;
                var dateObj     = new Date();
                var cTime       = dateObj.getTime();
                // 有效期内
                if(tokenTime > cTime){
                    this.token = token;
                    resolve(token);
                }
                // 获取新的 token
                else{
                    let p = this.getTokenFromApi(1);
                    p.then((res)=>{
                        this.token = res;
                        resolve(res);
                    }).catch((err)=>{
                        let p2 = this.getTokenFromApi(2);
                        p2.then((res)=>{
                            this.token = res;
                            resolve(res);
                        }).catch((err)=>{
                            reject(err);
                        });
                    });
                }
            }
        });
    },

    // 设置默认值补齐  "e09f14a1aab6307fc87c9506b6a7daaf18df6c5e"-1
    requestInit : function(sets, withLoginToken, url){
        if(!sets.data)    {sets.data       = {};}
        if(!sets.header)  {sets.header     = {};}
        if(!sets.timeout) {sets.timeout    = requestConfig.expiredTime;}
        if(!sets.dataType){sets.dataType   = 'json';}
        sets.header.token = this.token;
        if(withLoginToken){
            var loginToken = this.checkLogin();
            if(loginToken){sets.header.logintoken = (JSON.parse(loginToken)+ '-'+ this.getLocalStorage('userInfo','id'));}
        }
        if(requestConfig.apiBaseUrl != ''){
            sets.requestUrl = requestConfig.apiBaseUrl + url;
        }else{
            sets.requestUrl = url;
        }
        return sets;
    },
    getLocalStorage: function(key,field = ''){
        var info =  JSON.parse(localStorage.getItem(key));
        if(field&&info){
            return info[field]??'';
        }
        return info;
    },
    // 服务端 token 错误处理
    tokenErrorHandle : function (res){
        if(res.data && res.data == 'token error'){
            localStorage.removeItem(requestConfig.localTokenKeyName);
            return true;
        }
        return false;
    },

    // 请求基础函数
    base : function(url, sets, withLoginToken, type, isSign){
        return new Promise(async (resolve, reject)=>{
            let p  = this.getToken();
            p.then(async (token)=>{
                if(this.befor != null){
                    this.befor();
                    this.befor = null;
                }
                sets       = this.requestInit(sets, withLoginToken, url);
                if(!isSign){isSign = false;}
                if(isSign){
                    sets.data = this.sign(sets.data);
                }
                if(type == 'POST'){
                    if(!sets.header['content-type']){
                        sets.header['content-type'] = 'application/x-www-form-urlencoded';
                    }
                    var res = await  axios.post(url,sets.data,{headers : sets.header} )
                }else{
                    var res = await  axios.get(url,sets.data,{headers : sets.header} )
                }
                if(res.status == 200)
                {
                    if(res.data.code == 3)
                    {
                        localStorage.removeItem('userInfo');
                        localStorage.removeItem('uToken');
                        location.href="/login"
                    }else{
                        resolve(res.data);
                    }
                }else{
                    if(this.after != null){
                        this.after();
                        this.after = null;
                    }
                    reject("网络请求失败")
                }
            }).catch((err)=>{
                if(this.after != null){
                    this.after();
                    this.after = null;
                }
                reject(err)
            });
        });
    },

    // GET 请求
    get : function(url, sets, withLoginToken){
        return new Promise((resolve, reject)=>{
            reject();
            if(!sets){sets = {};}
            if(!withLoginToken){withLoginToken = false;}
            let p = this.base(url, sets, withLoginToken, 'GET');
            p.then((res)=>{
                if(this.tokenErrorHandle(res)){
                    let p2 = this.base(url, sets, withLoginToken, 'GET');
                    p2.then((res2)=>{
                        resolve(res2);
                    }).catch((err)=>{
                        reject(err);
                    });
                }else{
                    resolve(res);
                }
            }).catch((err)=>{
                reject(err);
            });
        });
    },

    // POST 请求
    post : function(url, data, isSign, withLoginToken){
        return new Promise((resolve, reject)=>{
            var sets = {data:data};
            if(!isSign){isSign = false;}
            if(!withLoginToken){withLoginToken = false;}
            let p = this.base(url, sets, withLoginToken, 'POST', isSign);
            p.then((res)=>{
                if(this.tokenErrorHandle(res)){
                    let p2 = this.base(url, sets, withLoginToken, 'POST', isSign);
                    p2.then((res2)=>{
                        resolve(res2);
                    }).catch((err)=>{
                        reject(err);
                    });
                }else{
                    resolve(res);
                }
            }).catch((err)=>{
                reject(err);
            });
        });
    },
    // debug 函数
    debug : function (content) {
        if(!requestConfig.debug){return ;}
        console.log(content);
    },

    // 签名算法
    sign : function (data) {
        if(data.gracesign){
            delete data.gracesign;
        }
        var vals = [];
        Object.keys(data).sort().map((key) => {
            vals.push(data[key]);
        });
        vals.push(this.token);
        var sign  = md5(vals.join('-'));
        data.gracesign = sign;
        return data;
    },

    // 登录检查
    // 登录后在本地保存一个 token
    checkLogin : function (notLoginDo){
        var loginToken = localStorage.getItem(requestConfig.userTokenKeyName);
        if(!loginToken || loginToken == ''){
            if(notLoginDo){
                console.log('请登录')
                setTimeout(()=>{
                    location.href="/login"
                }, 1500);
            }
            return false;
        }
        return loginToken;
    },
}
