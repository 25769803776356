import { createApp } from 'vue'
import ElementPlus, { ElMessage } from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store/index.js'
import filters from './utils/filters'
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
// app.config.globalProperties.$filters = filters
Object.keys(filters).forEach(key => {
    app.config.globalProperties[key] = filters[key]
})
app.config.globalProperties.tourl = (path)=>{
    router.push(path);
}
app.config.globalProperties.islogin = ()=>{
   return localStorage.getItem('uToken');
}
app.config.globalProperties.getUser = (field = '')=>{
    var userInfo =  JSON.parse(localStorage.getItem('userInfo'));
    if(field&&userInfo){
        return userInfo[field]??'';
    }
    return userInfo;
 }
app.config.globalProperties.getLocalStorage = (key,field = '')=>{
    var info =  JSON.parse(localStorage.getItem(key));
    if(field&&info){
        return JSON.parse(info[field]??'');
    }
    return info;
}
app.config.globalProperties.setLocalStorage = (key,value)=>{
    localStorage.setItem(key,JSON.stringify(value))
}
//封装请求
import gRequest from './utils/gRequest.js';
app.config.globalProperties.$gRequest = gRequest;
app.use(router);
app.use(store);
app.use(ElementPlus, { size: 'large', zIndex: 3000 })
app.mount('#app')
