export default{
    //调试
    debug: true,
    // 本地 Token 数据键名称
    localTokenKeyName : 'reqToken',
    // 用户登录 Token 数据键名称
    userTokenKeyName  : 'uToken',
    // token 有效期, 单位 秒 ，要与后端保持一致
    expiredTime       : 7200,
    appKey: 'sygcxy@2023%**'
}
