import Vuex from 'vuex'
export default new Vuex.Store({
  state () {
    return {
      config:null,
      isShowVip:false,//是否显示弹框
    }
  },
  mutations: {
    //修改字段
    editField(state,val) {
      state[val[0]] = val[1];
    },
  }
})
