// vue-router中提供3种的路由模式
import { createWebHistory, createRouter } from 'vue-router'

const routes = [
  {
    name:'login',
    path: '/login',
    meta:{
      alive:true
    },
    component: () => import('@/views/login.vue'),
  },
  {
    name:'home',
    path: '/',
    component: () => import('@/views/home.vue'),
  },
  {
    name:'categorys',
    path: '/categorys',
    component: () => import('@/views/categorys.vue')
  },
  {
    name:'chapter',
    path: '/chapter/:type',
    component: () => import('@/views/chapter.vue'),
  },
  {
    name:'online',
    path: '/online',
    component: () => import('@/views/online.vue'),
  },
  {
    name:'myWrong',
    path: '/myWrong',
    component: () => import('@/views/myWrong.vue'),
    meta:{
      isSou:true
    },
  },
  {
    name:'myCollect',
    path: '/myCollect',
    component: () => import('@/views/myCollect.vue'),
    meta:{
      isSou:true
    },
  }
]
const router = createRouter({
  // 路由的模式
  history: createWebHistory(),
  // 路由规则
  routes
})
const whiteList = ["login","register"];
//注册一个全局前置守卫,确保要调用 next 方法，否则钩子就不会被 resolved
router.beforeEach((to, from, next) => {
  if (whiteList.includes(to.name)) {
    next(); // 在免登录白名单，直接进入
  } else {
    if (localStorage.getItem('userInfo') && localStorage.getItem('userInfo')!='') {
      next();
    } else {
      next(`/login`); // 否则全部重定向到登录页
    }
  }
})
export default router
